.activeTabTitleIcon {
  fill: url(#greenGradientImage) !important;
}

.swiper-pagination-bullet-active{
  background-color: #5F6368 !important;
}

.MuiCardActionArea-root:hover {
  .normalTabTitleIcon {
    fill: url(#orangeGradientImage) !important;
  }

  .tabTitle {
    background: linear-gradient(to right, #F57F16, #009688);
    background-clip: text;
    color: transparent;
  }

  .tabTitleContainer {
    background-image: linear-gradient(to right, #F57F16, #009688);
    background-size: 100% 1px;
    background-repeat: no-repeat;
    background-position: bottom;
  }
}