.MuiCardActionArea-root:hover {
  .teamMemberName {
    background: linear-gradient(to right, #F57F16, #009688);
    background-clip: text;
    color: transparent;
  }

  .linkedInIcon {
    background-image: url(./img/linkedinColored.svg);
  }
}
