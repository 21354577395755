.terms {
  font-family: "inter";

  ol {
    counter-reset: item;
    padding-left: 1em;

    li {
      display: block;

      &:before {
        content: counters(item, ".") ". ";
        counter-increment: item;
        font-size: 14px;
        font-weight: 800
      }

      ol {
        li {
          &:before {
            font-weight: normal;
            font-size: 16px;
          }
        }
      }
    }
  }

  h2 {
    display: inline;
    font-size: 14px;
    font-weight: 800;
    text-transform: uppercase;
  }

  h3 {
    display: inline;
  }

  h4 {
    display: inline;
  }

  h5 {
    display: inline;
  }

  .document-meta {
    text-align: center;
  }

}

