#rcc-confirm-button {
  background: linear-gradient(to bottom right, #04766B, #0DCA73) !important;
  color: white !important;
  padding: 12px 38px !important;
  border-radius: 12px !important;
  &:hover{
    background: linear-gradient(to right, #F57F16, #009688) !important;
    border: 1px solid black !important;
    color: black !important;
  }
}