.getAppLightButton {
  &:hover {
    .innerDiv {
      background: linear-gradient(to right, #F57F16, #009688);

      p {
        color: black;
      }

      .arrowBox {
        background-image: url(./img/rightArrow.svg);
      }
    }
  }
}

.getAppSolid {
  &:hover {
    .googleHower {
      background-image: url(./img/googlePlayLogoDark.svg);
      transition: background-image 0.2s ease-in-out;
    }
  }

  .googleHower {
    transition: background-image 0.2s ease-in-out;
  }
}

.submitButton {
  &:hover {
    .submitButtonImage{
      background-image: url(./img/rightArrow.svg);
      transition: background-image 0.2s ease-in-out;
    }
  }
}